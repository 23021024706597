<template>
  <!-- icons from https://simpleicons.org/ -->
	<div>
    <!-- Parallax Pixel Background Animation -->
    <section class="animated-background">
      <div id="stars1"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
    </section>
    <!-- End of Parallax Pixel Background Animation -->

    <a id="profilePicture" href="#popup">
      <img src="./assets/2.png" alt="Profile Picture">
    </a>

    <div class="overlay" id="popup">
      <div class="popup">
        <div class="popup-photo">
          <a href="https://avatars0.githubusercontent.com/u/34409541" target="_blank">
            <img src="https://avatars0.githubusercontent.com/u/34409541" alt="Profile Picture">
          </a>
        </div>
        <div class="popup-quote">
          But... the sensation that I've lost something lingers for a longtime after I wake up.
        </div>
        <a class="popup-close" onclick="history.back()">&times;</a>
      </div>
    </div>

    <div id="userName">
      Afrizal MY
    </div>

    <div id="links">
      <!-- <a class="link" href="https://twitter.com/afrzlmy" target="_blank">
        <i class="fab fa-twitter">&nbsp;</i>Twitter
      </a> -->
      <a class="link" href="https://afrizalmy.com" target="_blank">
        <i class="fa fa-globe">&nbsp;</i>Website
      </a>
      <a class="link" href="https://github.com/afrizal423" target="_blank">
        <i class="fab fa-github">&nbsp;</i>Github
      </a>
      <a class="link" href="https://www.linkedin.com/in/afrizalmuhammadyasin" target="_blank">
        <i class="fab fa-linkedin">&nbsp;</i>Linkedin
      </a>
      <a class="link" href="https://www.youtube.com/channel/UCINMxhjCslsSufT92-qXhNA" target="_blank">
        <i class="fab fa-youtube">&nbsp;</i>Youtube
      </a>
      <a class="link" href="https://discord.com/users/712105145852231775" target="_blank">
        <i class="fab fa-discord">&nbsp;</i>Discord
      </a>
     
      <a class="link" href="https://saweria.co/afrizalmy" target="_blank">
        <i class="fa fa-coffee">&nbsp;</i>Treat me to coffee
      </a>
    </div>

    <div id="hashtag">
      #BeHappy❤
    </div>

  </div>
</template>
<script>
// no kismin import dt from './assets/link.json'
// export default {
//   name: 'App',
//   data(){
//     return{
//       myJson: dt
//     }
//   },
//   mounted() {
//     document.documentElement.className = 'theme-light';
//   },
// };
</script>
<style scoped>
/* .blue-color {
color:blue;
}
.green-color {
color:green;
}
.teal-color {
color:teal;
}
.yellow-color {
color:yellow;
}
.red-color {
color:red;
} */
</style>
